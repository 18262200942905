import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import BannerImg from '@/assets/img/for-every-child/onmymind_banner.png';
import BannerImgM from '@/assets/img/for-every-child/onmymind_bannerM@2x.png';
import BttImg from '@/assets/img/for-every-child/onmymind_bn_img@2x.png';
import BttImgM from '@/assets/img/for-every-child/onmymind_bn_imgM@2x.png';
import Obj1 from '@/assets/img/for-every-child/onmymind_obj1@2x.png';
import Obj2 from '@/assets/img/for-every-child/onmymind_obj2@2x.png';
import Obj3 from '@/assets/img/for-every-child/onmymind_obj3@2x.png';
import Obj4 from '@/assets/img/for-every-child/onmymind_obj4@2x.png';
import WedoImg from '@/assets/img/for-every-child/endviolence_wedo_img@2x.png';
import WedoImgM from '@/assets/img/for-every-child/endviolence_wedo_imgM@2x.png';
import Button from '@/components/Button';
import CampaignCard2, { CampaignData } from '@/components/Card/CampaignCard2';
import Container from '@/components/Container';
import Row from '@/components/Grid/Row';
import Image from '@/components/Image';
import { SectionTop } from '@/components/Nodiscrimination';
import PageComponent from '@/components/PageComponent';
import { ProcessList } from '@/components/ProcessList';
import Section from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { Tit } from '@/components/Titles';
import BR from '@/components/Typing/BR';
import YoutubeSingle from '@/components/YoutubeSingle';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { navigate, PageProps, Link } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

const itemData = [
  {
    id: 1,
    img: Obj1,
    desc: [
      {
        dec: `1/5 of children frequently experience depression in their daily lives`,
      },
    ],
  },
  {
    id: 2,
    img: Obj2,
    desc: [
      {
        dec: `Every 11 minutes, one child takes their own life`,
      },
    ],
  },
  {
    id: 3,
    img: Obj3,
    desc: [
      {
        dec: `75% of adolescents face mental health challenges before reaching the age of 24`,
      },
    ],
  },
  {
    id: 4,
    img: Obj4,
    desc: [
      {
        dec: `1/4 of children worldwide live with parents who suffer from mental health disorders`,
      },
    ],
  },
];

const SectionBanner = styled(Section)`
  padding-top: 90px;

  ${breakpoint(`mobile`)} {
    padding-top: 60px;
  }
`;

const SectionSort = styled(Section)`
  padding-bottom: 0 !important;
  .process-ol {
    justify-content: center;
    .list-item {
      width: 33.33%;
      margin-bottom: 124px;

      &:nth-child(1),
      &:nth-child(2) {
        width: 50%;
      }

      .dec-ul {
        max-width: 210px;
        margin: 0 auto;
        margin-top: 25px;

        strong {
          color: #1cabe2;
        }
      }
    }

    ${breakpoint(`tablet`)} {
      .list-item {
        margin-bottom: 70px;
        dt {
          padding: 0;
          .img-wrap {
            max-width: 80px;
          }
        }
        .dec-ul {
          max-width: 120px;
        }
      }
    }

    ${breakpoint(`tablet`)} {
      .list-item {
        width: 50%;

        &:nth-child(1) {
          width: 100%;
        }
      }
    }
  }
`;

const CardFlex = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px;
  align-items: stretch;

  & > li {
    width: 33.33%;
    padding: 0 16px;

    .card {
      height: 100%;
      position: relative;
      background: #fff;
      box-shadow: 24px 14px 40px 0 rgba(0, 0, 0, 0.08);
      border-radius: 0 0 20px 0;
      padding: 40px 32px;
      padding-top: 72px;

      dt {
        display: inline-block;
        background: #1cabe2;
        border-radius: 0 0 16px 0;
        line-height: 56px;
        color: #fff;
        padding: 0 32px;
        position: absolute;
        left: 0;
        top: -16px;
      }
    }

    ${breakpoint(`tablet`)} {
      width: 100%;
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;
const SectionInfluence = styled(Section)``;

const SectionWedo = styled(Section)`
  ${SectionHeader} {
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 316px;
      height: 316px;
      border-radius: 100%;
      background: #1cabe2;
      position: absolute;
      left: -116px;
      top: -134px;
      z-index: -1;
    }

    ${breakpoint(1160)} {
      &::before {
        width: 27.24vw;
        height: 27.24vw;
        left: -10vw;
        top: -11.55vw;
      }
    }
  }
  .flex-wrap {
    padding-right: 48.6%;
    position: relative;
    flex-wrap: wrap;

    .blit-list {
      width: 100%;
      font-weight: 700;
      & > li {
        margin-bottom: 16px;
        line-height: 1.7;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .col-img {
      width: 48.6%;
      position: absolute;
      right: 0;
      bottom: 0;
    }

    ${breakpoint(`tablet`)} {
      padding-right: 0;

      .col-img {
        width: 100%;
        position: relative;
        margin-top: 8px;
      }
    }
  }
`;

const SectionCampaign = styled(Section)`
  padding-bottom: 234px !important;

  ${breakpoint(`tablet`)} {
    padding-bottom: 120px !important;
  }
`;

const SectionBtt = styled(Section)`
  background: #1cabe2;
  padding-top: 46px;
  header {
    ${Tit} {
      line-height: 1.5;
    }
  }

  .flex {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 72px;
    position: relative;

    .col-dec {
      width: 59%;
      padding-right: 20px;

      .header-dec {
        color: #fff;
        margin-top: 32px;
        line-height: 1.7;
      }
      button {
        margin-top: 64px;
        max-width: 256px;
        width: 100%;
      }
    }

    .col-img {
      position: absolute;
      bottom: 0;
      right: -261px;
      width: 683px;
    }
  }
  ${breakpoint(1160)} {
    .flex {
      .col-img {
        right: -22.5vw;
        width: 58.88vw;
      }
    }
  }
  ${breakpoint(`mobile`)} {
    .flex {
      padding-bottom: 0;
      .col-dec {
        width: 100%;
        padding-right: 0;
        position: relative;
        z-index: 1;

        .header-dec {
          line-height: 2;
        }
        button {
          max-width: 152px;
        }
      }
      .col-img {
        position: relative;
        text-align: right;
        right: -20px;
        margin-top: -46px;
        width: 100%;
      }
    }
  }
`;

const EnOnMyMindPage: React.FC<PageProps> = ({ location }) => {
  const [slideData, setSlideData] = useState<CampaignData[]>([]);

  const loadSlideData = useCallback(async () => {
    try {
      const {
        data: childStory,
      } = await BoardCommonControllerService.foListAllUsingGet({
        boardCategoryCode: ['EV02'],
        outDateDispYn: `Y`,
      });
      setSlideData(
        childStory.map((story: BoardVo) => ({
          id: story.boardIndexNumber,
          title: story.subject,
          dec: story.contents,
          image: story.imgAttGrpNoTn,
          pop: `#OnMyMind 캠페인 활동`,
          youtube: story.optData1,
          thumYoutube: story.optData2,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadSlideData();
  }, [loadSlideData]);

  return (
    <LayoutWithTitle
      location={location}
      paddingBtt
      title="#OnMyMind"
      description="for every child, mental health"
    >
      <SectionTop
        css={`
          padding-top: 32px;
        `}
      >
        <Container>
          <Row
            justifyContent="flex-end"
            css={`
              margin-bottom: 24px;
            `}
          >
            <Button
              css={`
                padding: 0 30px;
                height: 40px;
              `}
              size="sm"
              onClick={() => navigate(`/what-we-do/for-every-child/onmymind`)}
            >
              KOR
            </Button>
          </Row>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1-5" color="sky" weight="normal">
                <PageComponent id="title1">
                  BTS supports #OnMyMind
                </PageComponent>
              </Tit>
            </h2>
            <p className="head-dec">
              <PageComponent id="content1">
                BTS and Big Hit Music have been supporting the campaign
                #ENDviolence by initiating the ‘LOVE MYSELF’ campaign, in
                partnership with the Korean Committee for UNICEF since November
                2017, to assist children and adolescents affected by violence.
                <br />
                The #OnMyMind campaign aims not only to prevent various forms of
                violence against children and adolescents but also to promote
                the understanding that it is natural for children and
                adolescents to experience various emotions, and to encourage
                them to freely talk about their feelings, mental health, and
                overall well-being.
              </PageComponent>
            </p>
          </SectionHeader>
        </Container>
        <PageComponent id="youtube1">
          <YoutubeSingle videoId="PIB0diQBOic" />
        </PageComponent>
      </SectionTop>

      <Container>
        <SectionBanner>
          <Link
            to="https://lovemyself2.onmymind.unicef.or.kr/"
            className="banner"
            target="_blank"
          >
            <Image
              pcSrc={BannerImg}
              mobileSrc={BannerImgM}
              alt="OnMyMind, UNICEF with BTS"
            />
          </Link>
        </SectionBanner>
      </Container>

      <SectionSort className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc sec-header">
            <h2>
              <Tit size="s1">
                How serious is <br />
                the mental health problem?
              </Tit>
            </h2>
          </SectionHeader>

          <ProcessList itemData={itemData} className="ProcessList" />
        </Container>
      </SectionSort>

      <SectionInfluence className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc sec-header">
            <h2>
              <Tit size="s1">
                Things to Consider
                <br />
                for Menth Health
              </Tit>
            </h2>
          </SectionHeader>
          <CardFlex>
            <li>
              <dl className="card">
                <dt>
                  <Tit size="s4">Mental health of parents and caregivers</Tit>
                </dt>
                <dd>
                  <p className="dec">
                    Children and adolescents can maintain healthy mental
                    conditions in a safe and joyful environment.
                  </p>
                </dd>
              </dl>
            </li>
            <li>
              <dl className="card">
                <dt>
                  <Tit size="s4">
                    Mental health of infants and preschool-aged children
                  </Tit>
                </dt>
                <dd>
                  <p className="dec">
                    Trauma experienced during infancy and the preschool period
                    can persistently impair mental health, having severe impacts
                    throughout an individual’s lifetime.
                  </p>
                </dd>
              </dl>
            </li>
            <li>
              <dl className="card">
                <dt>
                  <Tit size="s4">Mental health in emergency situations</Tit>
                </dt>
                <dd>
                  <p className="dec">
                    Experiencing emergencies such as natural disasters, poverty,
                    climate change, and COVID-19 can adversely affect children’s
                    mental health.
                  </p>
                </dd>
              </dl>
            </li>
          </CardFlex>
        </Container>
      </SectionInfluence>

      <SectionWedo className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">
                What UNICEF Does <br />
                Through #OnMyMind
              </Tit>
            </h2>
          </SectionHeader>
          <div className="flex-wrap flex">
            <ul className="blit-list">
              <li>
                Seeks to alter public stigma surrounding mental health and to
                advocate for the acceptance of all emotions.
              </li>
              <li>
                Supports children and adolescents in learning about mental
                health at school and ensures access to mental health services in
                a secure environment.
              </li>
              <li>
                Aids children and adolescents in understanding their own mental
                health conditions and promotes resilience, beginning with
                positive parenting at home.
              </li>
            </ul>
            <div className="col-img">
              <Image pcSrc={WedoImg} mobileSrc={WedoImgM} />
            </div>
          </div>
        </Container>
      </SectionWedo>
      <SectionBtt>
        <Container>
          <header>
            <h2
              css={`
                font-size: 40px;
                line-height: 1.5;
                letter-spacing: -2px;
                font-weight: normal;
                color: #ffffff;
                ${breakpoint(`mobile`)} {
                  font-size: 24px;
                  font-weight: normal;
                  line-height: 1.58;
                  letter-spacing: -1.2px;
                }
              `}
            >
              Your support can enable <BR only="desktop" />
              every child and adolescent <BR only="desktop" />
              to lead safe and happy lives.
            </h2>
          </header>
          <div className="flex">
            <div className="col-dec">
              <p className="header-dec">
                “When it comes to mental health, every country is a developing
                country.”
                <br />- Mental Health Specialist, Shekhar Saxena
              </p>

              <Button
                color="yellow"
                onClick={() =>
                  navigate(`/what-we-do/for-every-child/onmymind/en/donate`)
                }
              >
                Donate now
              </Button>
            </div>
            <div className="col-img">
              <Image pcSrc={BttImg} mobileSrc={BttImgM} />
            </div>
          </div>
        </Container>
      </SectionBtt>
    </LayoutWithTitle>
  );
};

export default EnOnMyMindPage;
